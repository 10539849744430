@import '../../../../Main.module.scss';

.sortable_list {
    position: relative;

    &_item {
        cursor: pointer;
        background-color: transparent;
        color: $primary-text-color;
        padding: 5px;

        &::selection {
            outline: none;
            color: $primary-text-color;
            background-color: transparent;
        }

        &_selected {
            cursor: pointer;
            color: $primary-text-color;
            padding: 5px;
            background-color: $ag-row-hover;
            text-decoration-style: none;

            &::selection {
                outline: none;
                color: $primary-text-color;
                background: transparent;
            }
        }
    }

    &_items {
        position: relative;
        overflow: auto;

        &_empty {
            text-align: center;
            width: 80%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &_actions {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: -80px;
        top: 50%;
        transform: translateY(-50%);
        gap: 15px;
    }

    &_action_button {
        border: 1px solid $stonex-disabled-light-gray !important;
        border-radius: 3px !important;
        padding: 0 !important;
    }

    &_action_icon {
        width: 48px !important;
        height: 48px !important;
    }
}
