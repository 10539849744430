@import '../../../Main.module.scss';

.presentation_templates {
    &_container {
        width: 100%;
        height: 100%;
        margin-bottom: 30px;
    }

    &_description {
        margin-bottom: 30px;
    }

    &_actions {
        &_row {
            margin-bottom: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &_search_container {
            position: relative;
        }

        &_search_input {
            width: 320px;
            height: 40px;
            padding-left: 35px;
            border: 1px solid $stonex-light-gray-blue;
            border-radius: 3px;
            margin: 0px;
        }

        &_search_icon {
            position: absolute;
            z-index: 1;
            font-size: 12px;
            margin-left: 10px;
            margin-top: 7px;
            color: $stonex-medium-gray;
        }
    }

    &_table {
        width: 100%;
        max-width: 100%;
    }

    &_actions {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 20px;
    }

    &_content {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 32px;

        &_order {
            min-height: 500px;
            width: 600px;
            border: 1px solid $stonex-gray-red-hue;
            border-radius: 3px;
        }
    }

    &_content_selector {
        &_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &_breadcrumb {
            font-size: 14px;
            list-style: 20px;
            font-weight: 500;
            height: 22px;
            margin: 0 0 15px 0;
            color: $alternative-neutral-text-color;
        }

        &_actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 20px;
            padding: 0 20px 20px 20px;
        }

        &_panes {
            display: flex;
            gap: 15px;
            align-items: flex-start;
        }

        &_pane {
            width: 220px;
            min-width: 220px;
            flex-shrink: 0;
            border: 1px solid $stonex-gray-red-hue;
            border-radius: 3px;
            height: 400px;
            overflow: auto;
        }

        &_pane_long {
            display: flex;
            flex-direction: row;
            gap: 15px;
            width: 456px;
            min-width: 456px;
            height: 400px;
            flex-shrink: 0;
            overflow: hidden;
            border: 1px solid $stonex-gray-red-hue;
            border-radius: 3px;
            padding: 10px;
        }

        &_selected_nodes {
            flex: 1;
            overflow: auto;
        }

        &_selected_image {
            width: 207px;
            height: 100%;
            flex-shrink: 0;
            display: flex;
            align-items: flex-start;
            justify-content: center;

            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }
        }
    }
}
